<head>
  <link rel="stylesheet" href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css"
    integrity="sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY=" crossorigin="" />

  <script src="https://unpkg.com/leaflet@1.9.4/dist/leaflet.js"
    integrity="sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo=" crossorigin=""></script>
</head>

<body>
  <section style="background-color: rgb(255, 255, 255);margin-top: 100px;" class="services section-bg">
    <div class="row animate__animated animate__fadeInDown">
      <div class="col-1">
      </div>
      <div class="col-10">
        <div class="map-container">
          <div class="map-frame">
            <div id="map"></div>
          </div>
        </div>
      </div>
      <div class="col-1">
      </div>
    </div>
    <div class="row animate__animated animate__fadeInDown">
      <div class="col-1">
      </div>
      <div class="col-10" style="justify-content: center; display: flex; margin-top: 2vh;">
        <button (click)="verTabla()" class="boton">Tabla comparativa</button>
      </div>
      <div class="col-1">
      </div>
    </div>
  </section>
</body>

<!-- Inicio Modal Tabla Comaparativa -->
<dialog id="modalTabla" style="border: none; border-radius: 1vh; width: 60%;" class="animate__zoomIn animate__animated">

  <div class="row" style="justify-content: right; display: flex;">
    <button class="botonCerrar" (click)="cerrarModalTabla()"><i class="fa fa-times"
        style="font-size: 20px;"></i></button>
  </div>
  <div style="justify-content: center; align-items: center; display: flex; margin-top: 2vh;" class="row">

    <div class="col-1"></div>
    <div class="col-10">
      <table class="table animate__fadeIn animate__animated" style="margin-top: 2vh; padding: 2vh;">
        <thead style="color: #000000;">
          <th scope="col">Sección</th>
          <th scope="col">Lista Nominal</th>
          <th scope="col">Personas Registradas</th>
        </thead>
        <tbody *ngIf="this.cargando === false">
          <tr
            *ngFor="let dato of conteo | paginate: { itemsPerPage: 10, currentPage: pages, id:'secciones' } let i = index">
            <td>{{dato.SECCION}}</td>
            <td>{{dato.TOTAL}}</td>
            <td>{{dato.PERSONAS_REGISTRADAS}}</td>
          </tr>
        </tbody>
        <tr *ngIf="this.cargando === true">
          <td colspan="3">
            <div class="d-flex justify-content-center" style="margin-top: 1vh;">
              <div class="spinner-border" style="color: #000000;"></div>
            </div>
          </td>
        </tr>
      </table>

      <div class="d-flex justify-content-center" style="margin-top: 2vh;">
        <pagination-controls id="secciones" class="my-pagination" (pageChange)="pages = $event" previousLabel="Anterior"
          nextLabel="Siguiente"></pagination-controls>
      </div>
    </div>
    <div class="col-1"></div>
  </div>
</dialog>
<!-- Final Modal Tabla Comaparativa -->
