<div class="animate__animated animate__jackInTheBox" style="text-align: center; justify-content: center;margin-top: 15vh;">
  <strong  style="font-size: 4vh;font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">Registro Municipal</strong>
</div>

<section class="photos-section py-5 d-flex justify-content-center animate__animated animate__fadeInUp" >
  <div class="container" style=" border-radius: 2vh;height: 60vh; box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.15);">
    <div class="row" style="margin-top: 10vh;">

      <div *ngIf="rol === '1' || rol === '2'" class="col-12 col-sm-6 col-xl-4 d-flex card-outer animate__pulse" style="text-align: center; justify-content: center;">
        <a href="admin/control/asistencia" class="card-title">
          <div class="card ">
            <div class="position-relative" style="text-align: center; justify-content: center;">
              <img style="margin-top: 2vh;" height="250" width="100%" src="https://i.imgur.com/hiyKTlZ.png"
                class="card-img-top " alt="">
                <svg class="position-absolute w-100" style="border: 0px;border-color: rgba(255, 255, 255, 0);">
                  <use xlink:href="#two" style="border: 0px; border-color: rgba(255, 255, 255, 0);"></use>
                </svg>
            </div>
            <div class="card-body">

              <strong>Registrar</strong>

            </div>
          </div>
        </a>
      </div>
      <div *ngIf="rol === '1'" class="col-12 col-sm-6 col-xl-4 d-flex card-outer animate__pulse" style="text-align: center; justify-content: center;">
        <a href="admin/control/asistencia/lista" class="card-title">
          <div class="card ">
            <div class="position-relative" style="text-align: center; justify-content: center;">
              <img style="margin-top: 2vh;" height="250" width="100%" src="https://i.imgur.com/C6g2Gn9.png"
                class="card-img-top " alt="">
                <svg class="position-absolute w-100" style="border: 0px;border-color: rgba(255, 255, 255, 0);">
                  <use xlink:href="#two" style="border: 0px; border-color: rgba(255, 255, 255, 0);"></use>
                </svg>
            </div>
            <div class="card-body">
              <strong>Lista de Registros</strong>
            </div>
          </div>
        </a>
      </div>
      <div *ngIf="rol === '1'" class="col-12 col-sm-6 col-xl-4 d-flex card-outer animate__pulse" style="text-align: center; justify-content: center;">
        <a href="admin/control/mapa" class="card-title">
          <div class="card ">
            <div class="position-relative" style="text-align: center; justify-content: center;">
              <img style="margin-top: 2vh;" height="250" width="100%" src="https://i.imgur.com/2yLxf8k.png"
                class="card-img-top " alt="">
                <svg class="position-absolute w-100" style="border: 0px;border-color: rgba(255, 255, 255, 0);">
                  <use xlink:href="#two" style="border: 0px; border-color: rgba(255, 255, 255, 0);"></use>
                </svg>
            </div>
            <div class="card-body">
              <strong>Mapa</strong>
            </div>
          </div>
        </a>
      </div>
