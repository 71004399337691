<section style="background-color:  #F3F3F4; height: 6vh; width: 100%;"></section>

<div class="wrapper">
  <section style="background-color: white; padding-left: 2vh; padding-right: 2vh; overflow: scroll;">
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane show active" id="activos" role="tabpanel">
        <div class="row">
          <div style="justify-content: left; display: flex; width: 100%;" class="col-sm-2">
            <button (click)="reporte()" class="boton">Descargar tabla</button>
          </div>
          <div style="justify-content: right; display: flex; width: 100%;" class="col-sm-10">
            <select name="filtro" id="filtro" #cbCampo
              style="border-top-left-radius: 7px; border-bottom-left-radius: 7px; height: 30px; margin-top: 2vh; border-color: black;">
              <option selected value="NOMBRE">NOMBRE</option>
              <option value="ZONA">SECCION</option>
              <option value="DOMICILIO">DOMICILIO</option>
              <option value="TELEFONO">TELÉFONO</option>
              <option value="SEXO">SEXO</option>
              <option value="IDENTIFICACION">IDENTIFICACION</option>
            </select>
            <input type="text" style="height: 30px; width: 300px; margin-top: 2vh; border-width: 2px;"
              placeholder="Ingresa tu búsqueda" (keyup)="buscar()" #ctCadena>
            <button disabled class="botonBuscar" style="margin-top: 2vh; margin-right: 4vh;">
              <i class="fa fa-search" aria-hidden="true" *ngIf="this.buscando === false"></i>
              <div class="d-flex justify-content-center" *ngIf="this.buscando === true">
                <div class="spinner-border" style="color: #ffffff; height: 18px; width: 18px;"></div>
              </div>
            </button>
          </div>
        </div>
        <table class="table animate__fadeIn animate__animated" style="margin-top: 2vh; padding: 2vh;">
          <thead style="color: #000000;">
            <th scope="col">NOMBRE</th>
            <th scope="col">SECCION</th>
            <th scope="col">DOMICILIO</th>
            <th scope="col">TELÉFONO</th>
            <th scope="col">SEXO</th>
            <th scope="col">IDENTIFICACION</th>
            <th scope="col">VECES REGISTRADA</th>
          </thead>
          <tbody>
            <tr class="tr" (click)="verTabla(dato.IDENTIFICACION)"
              *ngFor="let dato of activos | paginate: { itemsPerPage: 10, currentPage: pages, id:'activos' }; let i = index">
              <td>{{dato.NOMBRE}}</td>
              <td>{{dato.ZONA}}</td>
              <td>{{dato.DOMICILIO}}</td>
              <td>{{dato.TELEFONO}}</td>
              <td>{{dato.SEXO}}</td>
              <td>{{dato.IDENTIFICACION}}</td>
              <td>{{dato.NUMERO_REGISTRADA}}</td>
            </tr>
            <tr *ngIf="this.noEncontrado === true">
              <td style="justify-content: center; text-align: center;" colspan="7"><strong>No se encontro ningun
                  registro con ese dato</strong></td>
            </tr>
          </tbody>
          <tr *ngIf="this.cargando === true">
            <td colspan="7">
              <div class="d-flex justify-content-center">
                <div class="spinner-border" style="color: #000000;"></div>
              </div>
            </td>
          </tr>
        </table>
        <div class="d-flex justify-content-center">
          <pagination-controls id="activos" class="my-pagination" (pageChange)="pages = $event" previousLabel="Anterior"
            nextLabel="Siguiente"></pagination-controls>
        </div>
      </div>

    </div>

  </section>
</div>


<!--Imprimir Reportes-->
<div hidden>
  <table class="table table-bordered" id="reporte">
    <tbody class="thead-light">
      <tr>
        <th scope="col">NOMBRE</th>
        <th scope="col">SECCION</th>
        <th scope="col">DOMICILIO</th>
        <th scope="col">SEXO</th>
        <th scope="col">IDENTIFICACION</th>
        <th scope="col">VECES REGISTRADA</th>
      </tr>
      <tr *ngFor="let dato of activos; let i = index">
        <td>{{dato.NOMBRE}}</td>
        <td>{{dato.ZONA}}</td>
        <td>{{dato.DOMICILIO}}</td>
        <td>{{dato.SEXO}}</td>
        <td>{{dato.IDENTIFICACION}}</td>
        <td>{{dato.NUMERO_REGISTRADA}}</td>
      </tr>
    </tbody>
  </table>
</div>

<!-- Inicio Modal Tabla Comaparativa -->
<dialog id="modalTabla" style="border: none; border-radius: 1vh; width: 60%;" class="animate__zoomIn animate__animated">

  <div class="row" style="justify-content: right; display: flex;">
    <button class="botonCerrar" (click)="cerrarModalTabla()"><i class="fa fa-times"
        style="font-size: 20px;"></i></button>
  </div>

  <div style="justify-content: center; text-align: center; align-items: center; display: flex; margin-top: 2vh;"
    class="row">
    <h2>Motivos de Registro</h2>
  </div>

  <div style="justify-content: center; align-items: center; display: flex; margin-top: 1vh;" class="row">

    <div class="col-2"></div>
    <div class="col-8">
      <ul *ngIf="this.cargando1 === false">
        <li *ngFor="let dato of porId">{{dato.MOTIVO}}</li>
      </ul>

      <div class="d-flex justify-content-center"  *ngIf="this.cargando1 === true">
        <div class="spinner-border" style="color: #000000;"></div>
      </div>

    </div>
    <div class="col-2"></div>
  </div>
</dialog>
<!-- Final Modal Tabla Comaparativa -->
