<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<header id="header" class="fixed-top">

  <div class="container d-flex align-items-center">

    <h1 class="logo me-auto"><a href="inicio"><span></span></a></h1>
    <!-- Uncomment below if you prefer to use an image logo -->
    <!-- <a href="index.html" class="logo me-auto me-lg-0"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->

    <nav id="navbar" class="navbar order-last order-lg-0">

      <ul>
        <li *ngIf="rol === '1' || rol === '2'"><a href="admin/indice">Inicio</a></li>
        <li *ngIf="rol === '1'"><a href="admin/control/asistencia/lista">Lista de Registros</a></li>
        <li *ngIf="rol === '1' || rol === '2'"><a href="admin/control/asistencia">Registro</a></li>
        <li *ngIf="rol === '1'"><a href="admin/control/mapa">Mapa</a></li>
        <li *ngIf="rol === '1'"><a href="admin/control/motivos">Motivos</a></li>
        <li><a (click)="cerrar()" href="admin/login">Cerrar Sesión</a></li>
      </ul>
      <button class="bi bi-list mobile-nav-toggle" style="background: none; border: none;" data-bs-toggle="collapse"
        data-bs-parent="#accordion" href="#collapse1">
      </button>
    </nav>
  </div>
</header><!-- End Header -->
