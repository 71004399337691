
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { CommonModule } from '@angular/common';
import { IndexComponent } from '../index/index.component';
import { AuthEGuard } from 'src/app/core/guard/authE.guard';
import { ControlAsistComponent } from '../tramites-y-servicios/control-asist/control-asist.component';
import { ListaAsistComponent } from '../tramites-y-servicios/lista-asist/lista-asist.component';
import { MapaComponent } from '../mapa/mapa.component';
import { EventosComponent } from '../eventos/eventos.component';

const routes: Routes = [
  /*{
    path: '',
    component: DashboardComponent,
  },*/
  {
    path: 'indice',
    component: IndexComponent,
    canActivate: [AuthEGuard]
  },
  {
    path: 'control/asistencia',
    component: ControlAsistComponent,
    canActivate: [AuthEGuard]
  }, {
    path: 'control/asistencia/lista',
    component: ListaAsistComponent,
    canActivate: [AuthEGuard]
  }, {
    path: 'control/mapa',
    component: MapaComponent,
    canActivate: [AuthEGuard]
  }, {
    path: 'control/motivos',
    component: EventosComponent,
    canActivate: [AuthEGuard]
  }
];

@NgModule({
  imports: [CommonModule, RouterModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule { }
