import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';

@Component({
  selector: 'app-sidenav-admin',
  templateUrl: './sidenav-admin.component.html',
  styleUrls: ['./sidenav-admin.component.scss']
})
export class SidenavAdminComponent implements OnInit {
  token = false;
  rol: any;

  constructor(private router: Router, private service: GeneralService) { }

  ngOnInit(): void {
    if (localStorage.getItem("token")) {
      this.token = true;
    } else {
      this.token = false
    }

    this.rol = localStorage.getItem('rol');
  }

  cerrar() {
    localStorage.clear()
    location.reload()
  }
}
