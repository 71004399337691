import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import * as XLSX from 'xlsx';
import Chart from 'chart.js/auto';
import { FormControl, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-lista-asist',
  templateUrl: './lista-asist.component.html',
  styleUrls: ['./lista-asist.component.scss']
})
export class ListaAsistComponent {
  @ViewChild('cbCampo') cbCampo: ElementRef;
  @ViewChild('ctCadena') ctCadena: ElementRef;

  @ViewChild('cbCampoIn') cbCampoIn: ElementRef;
  @ViewChild('ctCadenaIn') ctCadenaIn: ElementRef;

  chart: any;
  yValues = [0, 0];
  label: any;
  canvas: any;
  imagen: any;
  spinner: any

  pages: number = 1;
  activos: any;
  inactivos: any;

  sector: any;
  nombre: any;
  sectores: any = [];
  zonas: any = [];

  sectorIn: any;
  nombreIn: any;
  sectoresIn: any = [];
  zonasIn: any = [];

  noEncontrado: any;
  noEncontradoIn: any;

  //CargaDeContenido
  cargando: any;
  cargando1: any;
  cargandoIn: any;
  objetivo: any
  listaObjetivos: any;
  n: any;
  dominio: any;
  listaIndicadores: any
  indicador: any
  idIndicador: any
  valor: any
  boton: any
  tabla: any;
  tablaValor: any
  //Busqueda
  buscando: any;
  buscandoIn: any;

  porId: any;

  name = 'Registros.xlsx';

  FEMENINO: any

  constructor(private router: Router, private general: GeneralService) { }

  ngOnInit() {

    if (localStorage.getItem('rol') === '1') {
      console.log('Permitido');
    } else {
      console.log('No permitido');
      this.router.navigate(['admin/indice']);
    }

    this.buscando = false;
    this.noEncontrado = false;
    this.cargando = true;
    this.cargando1 = true;

    this.general.listaAsistenciasContador().subscribe(res => {
      this.activos = res.body
      console.log(this.activos);
      this.cargando = false;
    })

    if (localStorage.getItem('token')) {
      this.boton = 1;
    }
  }

  cambio() {
    this.pages = 1;
  }

  buscar(): void {
    let columName: string = this.cbCampo.nativeElement.value;
    let value: any = this.ctCadena.nativeElement.value;

    if (value.length) {
      this.buscando = true;
      this.general.buscarAsistenciasConteo(columName, value).subscribe((res: any) => {
        this.activos = res.body
        this.pages = 1;
        if (this.activos.length === 0) {
          this.noEncontrado = true;
          this.buscando = false;
        } else {
          this.noEncontrado = false;
          this.buscando = false;
        }
      });
    } else {
      this.general.listaAsistenciasContador().subscribe(res => {
        this.activos = res.body
        this.pages = 1;
        this.noEncontrado = false;
        this.buscando = false;
        console.log(this.activos);
      })
    }
  }

  id(id: any) {
    this.router.navigate(['admin/proteccion-civil/servicios/', id])
  }

  descargarDatos() {
    this.exportToExcel();
  }

  generarTablaDatos() {

    this.general.buscarServiciosPC('DOMINIO', this.tablaValor).subscribe(res => {
      this.tabla = res.body;
      console.log(this.tabla);
    })
  }

  exportToExcel(): void {
    let element = document.getElementById('reporte');
    const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const book: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, worksheet, 'Sheet1');

    XLSX.writeFile(book, this.name);
  }

  reporte() {
    this.exportToExcel();
  }

  verTabla(id: any) {
    this.cargando1 = true;
    let dialog = document.getElementById("modalTabla") as HTMLDialogElement;
    dialog.showModal();
    this.personasPorID(id);
  }

  cerrarModalTabla() {
    let dialog = document.getElementById("modalTabla") as HTMLDialogElement;
    dialog.close();
  }

  personasPorID(id: any) {
    this.general.asistId(id).subscribe(res => {
      this.porId = res.body;
      console.log(this.porId);
      this.cargando1 = false;
    })
  }
}
