import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import * as XLSX from 'xlsx';
import Chart from 'chart.js/auto';
import { FormControl, FormGroup } from '@angular/forms';
import { SweetAlertService } from 'src/services/sweet-alert.service';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.scss']
})
export class EventosComponent {
  @ViewChild('cbCampo') cbCampo: ElementRef;
  @ViewChild('ctCadena') ctCadena: ElementRef;

  @ViewChild('cbCampoIn') cbCampoIn: ElementRef;
  @ViewChild('ctCadenaIn') ctCadenaIn: ElementRef;

  chart: any;
  yValues = [0, 0];
  label: any;
  canvas: any;
  imagen: any;
  spinner: any

  pages: number = 1;
  activos: any;
  inactivos: any;

  sector: any;
  nombre: any;
  sectores: any = [];
  zonas: any = [];

  sectorIn: any;
  nombreIn: any;
  sectoresIn: any = [];
  zonasIn: any = [];

  noEncontrado: any;
  noEncontradoIn: any;

  //CargaDeContenido
  cargando: any;
  cargando1: any;
  cargandoIn: any;
  objetivo: any
  listaObjetivos: any;
  n: any;
  dominio: any;
  listaIndicadores: any
  indicador: any
  idIndicador: any
  valor: any
  boton: any
  tabla: any;
  tablaValor: any
  //Busqueda
  buscando: any;
  buscandoIn: any;

  porId: any;

  name = 'Registros.xlsx';

  motivoActual: any;
  idMotivoActual: any;

  motivoEditar: any;
  nombreActual: any;
  editarNombre: any;
  idEditar: any;

  motivoForm = new FormGroup({
    NOMBRE: new FormControl('')
  });

  actualizarMotivoForm = new FormGroup({
    ID: new FormControl(),
    ESTATUS: new FormControl()
  });

  editarNombreMotivoForm = new FormGroup({
    ID: new FormControl(),
    NOMBRE: new FormControl('')
  });

  constructor(private router: Router, private general: GeneralService, private alerts: SweetAlertService) { }

  ngOnInit() {

    if (localStorage.getItem('rol') === '1') {
      console.log('Permitido');
    } else {
      console.log('No permitido');
      this.router.navigate(['admin/indice']);
    }

    this.buscando = false;
    this.noEncontrado = false;
    this.cargando = true;
    this.cargando1 = true;
    this.nombre = '';
    this.editarNombre = '';

    this.general.listaRazones().subscribe(res => {
      this.activos = res.body
      console.log(this.activos);
      this.general.listaMotivos().subscribe(resp => {
        this.motivoActual = resp.body;
        this.idMotivoActual = this.motivoActual[0].ID;
        this.cargando = false;
        console.log(this.idMotivoActual);
      })
    });

    if (localStorage.getItem('token')) {
      this.boton = 1;
    }
  }

  cambio() {
    this.pages = 1;
  }

  buscar(): void {
    let columName: string = this.cbCampo.nativeElement.value;
    let value: any = this.ctCadena.nativeElement.value;

    if (value.length) {
      this.buscando = true;
      this.general.buscarAsistenciasConteo(columName, value).subscribe((res: any) => {
        this.activos = res.body
        this.pages = 1;
        if (this.activos.length === 0) {
          this.noEncontrado = true;
          this.buscando = false;
        } else {
          this.noEncontrado = false;
          this.buscando = false;
        }
      });
    } else {
      this.general.listaAsistenciasContador().subscribe(res => {
        this.activos = res.body
        this.pages = 1;
        this.noEncontrado = false;
        this.buscando = false;
        console.log(this.activos);
      })
    }
  }

  id(id: any) {
    this.router.navigate(['admin/proteccion-civil/servicios/', id])
  }

  descargarDatos() {
    this.exportToExcel();
  }

  generarTablaDatos() {
    this.general.buscarServiciosPC('DOMINIO', this.tablaValor).subscribe(res => {
      this.tabla = res.body;
      console.log(this.tabla);
    })
  }

  exportToExcel(): void {
    let element = document.getElementById('reporte');
    const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const book: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, worksheet, 'Sheet1');

    XLSX.writeFile(book, this.name);
  }

  reporte() {
    this.exportToExcel();
  }

  verTabla() {
    let dialog = document.getElementById("modalTabla") as HTMLDialogElement;
    dialog.showModal();
  }

  cerrarModalTabla() {
    let dialog = document.getElementById("modalTabla") as HTMLDialogElement;
    dialog.close();
  }

  personasPorID(id: any) {
    this.general.asistId(id).subscribe(res => {
      this.porId = res.body;
      console.log(this.porId);
      this.cargando1 = false;
    })
  }

  agregarEvento() {

    this.motivoForm.value.NOMBRE = this.nombre;

    this.actualizarMotivoForm.setValue({
      'ID': this.idMotivoActual,
      'ESTATUS': 0
    })

    this.cerrarModalTabla();
    this.alerts.alertaConfirmacionAgregar('Agregar Nuevo Motivo', '¿Desea agregar el nuevo motivo?')
      .then((res: any) => {

        if (res.isConfirmed) {
          if (this.motivoForm.value.NOMBRE !== ''
          ) {
            console.log(this.motivoForm.value);

            this.general.agregarNuevoMotivo(this.motivoForm.value).subscribe(data => {
              this.general.actualizarMotivo(this.actualizarMotivoForm.value).subscribe(resp => {
                this.alerts.realizado('Completado', 'Se ha agregado el motivo con exito').then((res: any) => {
                  this.general.listaRazones().subscribe(res => {
                    this.activos = res.body
                    console.log(this.activos);
                    this.general.listaMotivos().subscribe(resp => {
                      this.motivoActual = resp.body;
                      this.idMotivoActual = this.motivoActual[0].ID;
                      console.log(this.idMotivoActual);
                      this.cargando = false;
                    })
                  });
                });
              })
            }, error => {
              this.alerts.alertaError('Ocurrio un error', 'Revise su conexión o intentelo de nuevo').then(res => {
                this.verTabla();
              });
            })
          } else {
            this.alerts.alertaError('Error de registro', 'Todos los campos son obligatorios').then(res => {
              this.verTabla();
            });
          }
        }
      })
  }

  verEditar(id: any) {
    this.general.razonID(id).subscribe(res => {
      this.motivoEditar = res.body;
      this.nombreActual = this.motivoEditar[0].NOMBRE;
      this.idEditar = this.motivoEditar[0].ID;
      let dialog = document.getElementById("modalEditar") as HTMLDialogElement;
      dialog.showModal();
    })

  }

  cerrarModalEditar() {
    let dialog = document.getElementById("modalEditar") as HTMLDialogElement;
    dialog.close();
  }

  editarEvento() {

    this.editarNombreMotivoForm.value.ID = this.idEditar;
    this.editarNombreMotivoForm.value.NOMBRE = this.editarNombre;

    this.cerrarModalEditar();
    this.alerts.alertaConfirmacionAgregar('Editar Nombre', '¿Desea editar el nombre del motivo?')
      .then((res: any) => {

        if (res.isConfirmed) {
          if (this.editarNombreMotivoForm.value.NOMBRE !== ''
          ) {
            console.log(this.editarNombreMotivoForm.value);

            this.general.actualizarMotivo(this.editarNombreMotivoForm.value).subscribe(resp => {
              this.alerts.realizado('Completado', 'Se ha cambiado el nombre del motivo con exito').then((res: any) => {
                this.general.listaRazones().subscribe(res => {
                  this.activos = res.body
                  console.log(this.activos);
                  this.general.listaMotivos().subscribe(resp => {
                    this.motivoActual = resp.body;
                    this.idMotivoActual = this.motivoActual[0].ID;
                    console.log(this.idMotivoActual);
                    this.cargando = false;
                  })
                });
              })
            }, error => {
              this.alerts.alertaError('Ocurrio un error', 'Revise su conexión o intentelo de nuevo').then(res => {
                this.verEditar(this.idEditar);
              });
            })
          } else {
            this.alerts.alertaError('Error', 'Todos los campos son obligatorios').then(res => {
              this.verEditar(this.idEditar);
            });
          }
        }
      })
  }
}

