<section style="background-color:  #F3F3F4; height: 6vh; width: 100%;"></section>

<div class="wrapper">
  <section style="background-color: white; padding-left: 2vh; padding-right: 2vh; overflow: scroll;">
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane show active" id="activos" role="tabpanel">
        <div class="row">
          <div style="justify-content: left; display: flex; width: 100%;" class="col-sm-2">
            <button (click)="verTabla()" class="boton">Agregar Motivo</button>
          </div>
        </div>
        <table class="table animate__fadeIn animate__animated" style="margin-top: 2vh; padding: 2vh;">
          <thead style="color: #000000;">
            <th scope="col">NOMBRE</th>
            <th scope="col"></th>
          </thead>
          <tbody>
            <tr
              *ngFor="let dato of activos | paginate: { itemsPerPage: 10, currentPage: pages, id:'activos' }; let i = index">
              <td>{{dato.NOMBRE}} <button class="botonE" (click)="verEditar(dato.ID)">Editar nombre</button></td>
              <td *ngIf="dato.ESTATUS === 0">Motivo pasado</td>
              <td *ngIf="dato.ESTATUS === 1">Motivo activo</td>
            </tr>
          </tbody>
          <tr *ngIf="this.cargando === true">
            <td colspan="2">
              <div class="d-flex justify-content-center">
                <div class="spinner-border" style="color: #000000;"></div>
              </div>
            </td>
          </tr>
        </table>
        <div class="d-flex justify-content-center">
          <pagination-controls id="activos" class="my-pagination" (pageChange)="pages = $event" previousLabel="Anterior"
            nextLabel="Siguiente"></pagination-controls>
        </div>
      </div>

    </div>

  </section>
</div>

<!-- Inicio Modal Nuevo Motivo -->
<dialog id="modalTabla" style="border: none; border-radius: 1vh; width: 60%;" class="animate__zoomIn animate__animated">

  <div class="row" style="justify-content: right; display: flex;">
    <button class="botonCerrar" (click)="cerrarModalTabla()"><i class="fa fa-times"
        style="font-size: 20px;"></i></button>
  </div>

  <div style="justify-content: center; text-align: center; align-items: center; display: flex; margin-top: 2vh;"
    class="row">
    <h2>Motivo</h2>
  </div>

  <div style="justify-content: center; align-items: center; display: flex; margin-top: 1vh;" class="row">

    <div class="col-1"></div>
    <div class="col-10">
      <label style="margin-top: 1vh; margin-left: 1vh; width: 100%;"><strong>Nombre:</strong>
        <input type="text" class="form-control" aria-describedby="basic-addon1" [(ngModel)]="nombre">
      </label>
    </div>
    <div class="col-1"></div>

    <div class="col-1"></div>
    <div class="col-10" style="justify-content: center; display: flex;">
      <button (click)="agregarEvento()" class="boton">Agregar Motivo</button>
    </div>
    <div class="col-1"></div>

  </div>
</dialog>
<!-- Final Modal Nuevo Motivo -->

<!-- Inicio Modal Editar Motivo -->
<dialog id="modalEditar" style="border: none; border-radius: 1vh; width: 60%;"
  class="animate__zoomIn animate__animated">

  <div class="row" style="justify-content: right; display: flex;">
    <button class="botonCerrar" (click)="cerrarModalEditar()"><i class="fa fa-times"
        style="font-size: 20px;"></i></button>
  </div>

  <div style="justify-content: center; text-align: center; align-items: center; display: flex; margin-top: 2vh;"
    class="row">
    <h2>Editar Nombre</h2>
  </div>

  <div style="justify-content: center; align-items: center; display: flex; margin-top: 1vh;" class="row">

    <div class="col-1"></div>
    <div class="col-10">
      <label style="margin-top: 1vh; margin-left: 1vh; width: 100%;"><strong>Nombre Actual:</strong>
        <input type="text" class="form-control" aria-describedby="basic-addon1" [(ngModel)]="nombreActual" disabled>
      </label>
    </div>
    <div class="col-1"></div>

    <div class="col-1"></div>
    <div class="col-10">
      <label style="margin-top: 1vh; margin-left: 1vh; width: 100%;"><strong>Nuevo Nombre:</strong>
        <input type="text" class="form-control" aria-describedby="basic-addon1" [(ngModel)]="editarNombre">
      </label>
    </div>
    <div class="col-1"></div>

    <div class="col-1"></div>
    <div class="col-10" style="justify-content: center; display: flex;">
      <button (click)="editarEvento()" class="boton">Guardar</button>
    </div>
    <div class="col-1"></div>

  </div>
</dialog>
<!-- Final Modal Editar Motivo -->
