import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';
import jsPDF from 'jspdf';
@Component({
  selector: 'app-control-asist',
  templateUrl: './control-asist.component.html',
  styleUrls: ['./control-asist.component.scss']
})
export class ControlAsistComponent {
  razon: any
  concepto: any
  cantidad: any
  solicitante: any
  archivos: string[] = [];
  estatus: any
  secciones: any;

  nuevoPC = new FormGroup({
    NOMBRE: new FormControl('', Validators.required),
    ZONA: new FormControl('', Validators.required),
    DOMICILIO: new FormControl('', Validators.required),
    TELEFONO: new FormControl('', Validators.required),
    SEXO: new FormControl('', Validators.required),
    MOTIVO: new FormControl('', Validators.required),
    IDENTIFICACION: new FormControl('', Validators.required)
  });

  constructor(private api: GeneralService, private alerts: SweetAlertService, private router: Router,) { }

  ngOnInit(): void {
    this.api.listaSecciones().subscribe(res=>{
      this.secciones = res.body;
      console.log(this.secciones);
    })
  }

  postForm(form: any) {

    this.alerts.alertaConfirmacionAgregar('Registro Datos Generales', '¿Desea realizar el registro?')
      .then((res: any) => {

        if (res.isConfirmed) {
          if (form.NOMBRE !== '' &&
            form.ZONA !== '' &&
            form.DOMICILIO !== '' &&
            form.TELEFONO !== '' &&
            form.SEXO !== '' &&
            form.MOTIVO !== '' &&
            form.IDENTIFICACION !== ''
          ) {
            console.log(form);

            this.api.RegistroAsistencia(form).subscribe(data => {
              console.log(data);
              this.alerts.realizado('Completado', 'Se ha realizado el registro con exito').then((res: any) => {
                location.reload()
              })
            }, error => {
              this.alerts.alertaError('Ups', 'Error de registro')
            })
          } else {
            this.alerts.alertaError('Error de registro', 'Todos los campos son obligatorios');
          }
        }
      })
  }
}
