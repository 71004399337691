import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  rol: any;

  constructor(private router: Router, private service: GeneralService) { }

  ngOnInit(): void {
    this.rol = localStorage.getItem('rol');
  }
}
